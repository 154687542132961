import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SocialIcons from './SocialIcons';

import {
    FooterDivider,
    FooterContainer
} from '../Styles/Footer.style';

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata{
                    author
                }
            }
        }
    `)

    return (
        <footer>
            <FooterDivider />
            <FooterContainer>
                <p>Created by { data.site.siteMetadata.author }, © 2020</p>
                <SocialIcons />
            </FooterContainer>
        </footer>
    )
}

export default Footer;