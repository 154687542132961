import React from 'react';
import { Link } from 'gatsby'

import ShadenCodesLogo from '../Images/logo.png';


import {
    HeaderContainer,
    Logo,
    NavList,
    NavLink,
    NavIcon
} from '../Styles/Header.style';

class Header extends React.Component { 
    
    state = { MenuOpen: false };

    handleMenuClick = () => {
        this.setState({MenuOpen: !this.state.MenuOpen});
    }

    render() {
        return (
            <HeaderContainer>
                <Link to="/">
                    <Logo src={ShadenCodesLogo} alt="ShadenCodes Logo"/>
                </Link>         
                <NavIcon onClick={this.handleMenuClick} MenuOpen={this.state.MenuOpen}/>
                <NavList className={this.state.MenuOpen ? 'active' : ''}>
                    <li><NavLink to="/">Home</NavLink></li>
                    <li><NavLink to="https://payhip.com/b/b1Cfk" target='_blank'>E-Book</NavLink></li>
                    <li><NavLink to="/About">About</NavLink></li>
                    <li><NavLink to="/Portfolio">Portfolio</NavLink></li>
                    <li><NavLink to="/Blog">Blog</NavLink></li>
                    <li><NavLink to="/Contact">Contact</NavLink></li>
                </NavList>
            </HeaderContainer>
        );
    }
}

export default Header;