import React from "react";

import Header from './Header';
import Footer from './Footer';

import {
    GlobalStyle,
    LayoutContainer,
    Content
} from '../Styles/layout.style';

const Layout = (props) => {
    return (
        <LayoutContainer>
            <GlobalStyle />
            <Content>
                <Header />
                {props.children}
            </Content>
            <Footer />
        </LayoutContainer>
    )
}

export default Layout;