import styled from "styled-components";

export const FooterDivider = styled.div`
    height: 2px;
	margin: 0px 30px 15px 30px;
	background-color: black;
	margin-top: 5rem;
`;

export const FooterContainer = styled.div`
	display: flex;
	margin: 15px 30px;

	p {
		flex-grow: 1;
	}
`;