import styled, { keyframes } from "styled-components";
import { createGlobalStyle } from "styled-components"


export const GlobalStyle = createGlobalStyle`
    body{
        background-color: white;
        font-family: 'Montserrat';
        color: #1F1F1F;
    }
`;

const FadeIn = keyframes`
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
`;

export const LayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    animation: ${FadeIn} ease-in-out forwards 3s;
`;

export const Content = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 85vh;
`;