import React from "react";
import { graphql, useStaticQuery } from 'gatsby';

import {
    SocialIconsContainer,
    IconContainer
} from '../Styles/SocialIcons.style';

const SocialIcons = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata{
                    instagram_url,
                    twitter_url,
                    github_url,
                    linkedin_url,
                    sendmailto
                }
            }
        }
    `)

    return (
        <SocialIconsContainer>

            <a title='Instagram - ShadenCodes' href={data.site.siteMetadata.instagram_url} target='_blank' rel='noreferrer'>
                <IconContainer>
                    <svg className='instagram' width='38' height='28' viewBox='0 0 30 28'>
                        <path
                            d='M14.9999 10.0947C12.8495 10.0947 11.0946 11.8496 11.0946 14C11.0946 16.1504 12.8495 17.9053 14.9999
                            17.9053C17.1503 17.9053 18.9052 16.1504 18.9052 14C18.9052 11.8496 17.1503 10.0947 14.9999 10.0947ZM26.7128
                            14C26.7128 12.3828 26.7274 10.7803 26.6366 9.16602C26.5458 7.29102 26.1181 5.62695 24.747 4.25586C23.3729 2.88184
                            21.7118 2.45703 19.8368 2.36621C18.2196 2.27539 16.6171 2.29004 15.0028 2.29004C13.3856 2.29004 11.7831 2.27539 10.1688
                            2.36621C8.29384 2.45703 6.62978 2.88477 5.25868 4.25586C3.88466 5.62988 3.45986 7.29102 3.36904 9.16602C3.27822 10.7832
                            3.29286 12.3857 3.29286 14C3.29286 15.6143 3.27822 17.2197 3.36904 18.834C3.45986 20.709 3.88759 22.373 5.25868 23.7441C6.63271
                            25.1182 8.29384 25.543 10.1688 25.6338C11.786 25.7246 13.3886 25.71 15.0028 25.71C16.62 25.71 18.2226 25.7246 19.8368
                            25.6338C21.7118 25.543 23.3759 25.1152 24.747 23.7441C26.121 22.3701 26.5458 20.709 26.6366 18.834C26.7304 17.2197 26.7128
                            15.6172 26.7128 14V14ZM14.9999 20.0088C11.6747 20.0088 8.99111 17.3252 8.99111 14C8.99111 10.6748 11.6747 7.99121 14.9999
                            7.99121C18.3251 7.99121 21.0087 10.6748 21.0087 14C21.0087 17.3252 18.3251 20.0088 14.9999 20.0088ZM21.2548 9.14844C20.4784
                            9.14844 19.8515 8.52149 19.8515 7.74512C19.8515 6.96875 20.4784 6.3418 21.2548 6.3418C22.0311 6.3418 22.6581 6.96875 22.6581
                            7.74512C22.6583 7.92947 22.6222 8.11206 22.5517 8.28242C22.4813 8.45278 22.3779 8.60757 22.2476 8.73793C22.1172 8.86829 21.9624
                            8.97165 21.7921 9.04209C21.6217 9.11253 21.4391 9.14867 21.2548 9.14844V9.14844Z'
                            fill='#9a9a9a'
                        />
                    </svg> 
                </IconContainer> 
            </a> 

            <a title='GitHub' href={data.site.siteMetadata.github_url} target='_blank' rel='noreferrer'> 
                <IconContainer>
                    <svg className='github' width='38' height='28' viewBox='0 0 30 28'>
                        <path
                            d='M14.9883 1.23535C7.74316 1.23242 1.875 7.09766 1.875 14.3369C1.875 20.0615 5.5459 24.9277 10.6582 26.7148C11.3467 26.8877
                            11.2412 26.3984 11.2412 26.0645V23.7939C7.26563 24.2598 7.10449 21.6289 6.83789 21.1895C6.29883 20.2695 5.02441 20.0352 5.40527
                            19.5957C6.31055 19.1299 7.2334 19.7129 8.30273 21.292C9.07617 22.4375 10.585 22.2441 11.3496 22.0537C11.5166 21.3652 11.874 20.75
                            12.3662 20.2725C8.24707 19.5342 6.53027 17.0205 6.53027 14.0322C6.53027 12.582 7.00781 11.249 7.94531 10.1738C7.34766 8.40137
                            8.00098 6.88379 8.08887 6.6582C9.79102 6.50586 11.5605 7.87696 11.6982 7.98535C12.665 7.72461 13.7695 7.58692 15.0059 7.58692C16.248
                            7.58692 17.3555 7.73047 18.3311 7.99414C18.6621 7.74219 20.3027 6.56445 21.8848 6.70801C21.9697 6.9336 22.6084 8.41602 22.0459
                            10.165C22.9951 11.2432 23.4785 12.5879 23.4785 14.041C23.4785 17.0352 21.75 19.5518 17.6191 20.2783C17.973 20.6263 18.2539 21.0413
                            18.4455 21.499C18.6372 21.9567 18.7357 22.4481 18.7354 22.9443V26.2402C18.7588 26.5039 18.7354 26.7647 19.1748 26.7647C24.3633
                            25.0156 28.0986 20.1143 28.0986 14.3398C28.0986 7.09766 22.2275 1.23535 14.9883 1.23535V1.23535Z'
                            fill='#9a9a9a'
                        />
                    </svg>
                </IconContainer>
            </a>

            <a title='Linked-in' href={data.site.siteMetadata.linkedin_url} target='_blank' rel='noreferrer'>
                <IconContainer>
                    <svg className='linked-in' width='38' height='28' viewBox='0 0 30 28'>
                        <path 
                            d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966
                            0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4
                            0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                            fill='#9a9a9a'
                        />
                    </svg>
                </IconContainer>
            </a>

            <a title='Twitter' href={data.site.siteMetadata.twitter_url} target='_blank' rel='noreferrer'>
                <IconContainer>
                    <svg className='twitter' width='38' height='28' viewBox='0 0 30 28'>
                        <path
                            d='M27.1875 6.45019C26.291 6.83691 25.3154 7.11523 24.3105 7.22363C25.3538 6.60381 26.1351 5.6246 26.5078 4.46972C25.5288 5.05211 24.4563 5.4605
                            23.3379 5.67675C22.8704 5.17703 22.3051 4.77892 21.6771 4.50725C21.049 4.23557 20.3718 4.09614 19.6875 4.09765C16.9189 4.09765 14.6924 6.34179 14.6924
                            9.0957C14.6924 9.48242 14.7393 9.86914 14.8154 10.2412C10.6699 10.0244 6.97266 8.04394 4.51465 5.01172C4.06677 5.7767 3.83207 6.64773 3.83496
                            7.53418C3.83496 9.26855 4.7168 10.7978 6.06152 11.6973C5.26906 11.6661 4.49514 11.4482 3.80273 11.0615V11.123C3.80273 13.5518 5.51953 15.5645 7.80762 
                            16.0273C7.378 16.1389 6.93606 16.196 6.49219 16.1973C6.16699 16.1973 5.85937 16.165 5.54883 16.1211C6.18164 18.1016 8.02441 19.54 10.2187 19.5869C8.50195 
                            20.9316 6.35156 21.7227 4.0166 21.7227C3.59766 21.7227 3.21094 21.708 2.80957 21.6611C5.02441 23.082 7.65234 23.9023 10.4824 23.9023C19.6699 23.9023 
                            24.6973 16.291 24.6973 9.68457C24.6973 9.46777 24.6973 9.25097 24.6826 9.03418C25.6553 8.32226 26.5078 7.44043 27.1875 6.45019Z'
                            fill='#9a9a9a'
                        />
                    </svg>
                </IconContainer>
            </a>

            <a title='Mail' href={data.site.siteMetadata.sendmailto}>
                <IconContainer>
                    <svg className='mail' width='38' height='28' viewBox='0 0 30 28'>
                        <path 
                            d="M12 .02c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.99 6.98l-6.99 5.666-6.991-5.666h13.981zm.01 10h-14v-8.505l7 5.673 
                            7-5.672v8.504z"
                            fill='#9a9a9a'
                        />
                    </svg>
                </IconContainer>
            </a>
            
        </SocialIconsContainer>
    );
}

export default SocialIcons;