import styled from "styled-components";
import { Link } from 'gatsby';
import NavBurgerIcon from '../components/NavBurgerIcon'

export const HeaderContainer = styled.header`
`;

export const Logo = styled.img`
    src: ${props => props.src};
    width: 100px;
    height: 75px;
   /* margin: 10px 0px 0px 30px;*/
`;

export const NavList = styled.ul`
    float: right;
    display: flex;
    list-style-type: none;
    margin: 25px 0;

    @media screen and (max-width: 800px) {
        display: none;
        width: 100%;
        height: 600px;
        position: absolute;
        top: 80px;
        padding: 0;

        &.active {
            display: flex;
            flex-direction: column;
            background-color: white;
            left: 0px;
            opacity: 0.95;
            z-index: 1;
        }
    }   
`;

export const NavLink = styled(Link)`
    color: black;
    font-size: 1rem;
    margin: 1.3rem;
    text-decoration: none;

    @media screen and (max-width: 800px) {
        text-align: center;
        width: 100%;
        display: table;
        margin: 1.3rem 0;
        padding: 1rem 0;
        font-size: 1.1rem;
        font-weight: bold;

        &:hover {
            background-color: #ddd;
        }
    }
`;

export const NavIcon = styled(NavBurgerIcon)`
    display: none;
    margin: 35px 20px;

    @media screen and (max-width: 800px) {
        display: flex;
        float: right;
    }   
`;

