import styled from "styled-components";

export const NavBurgerContainer = styled.div`
    width: 40px;
    height: 32px;
`;

export const Stick = styled.div`
    width: 40px;
    height: 5px;
    background: black;
    position: absolute;
    transition: all 0.6s;
    border-radius: 5px;

    &:before, &:after  {
        width: 40px;
        height: 5px;
        background: black;
        position: absolute;
        transition: all 0.6s;
        border-radius: 5px;
    }

    &:before {
        content: '';
        top: -16px;
        left: 0px;
    }

    &:after {
        content: '';
        top: 16px;
        left: 0px;
    }

    &.open{
        transform: translateX(-50px);
        background: transparent;
    }

    &.open:after{
        transform: rotate(-45deg) translate(50px, 28px);
    }

    &.open:before{
        transform: rotate(45deg) translate(50px, -28px);
    }
`;
