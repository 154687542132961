import React from 'react';

import {
    NavBurgerContainer,
    Stick
} from '../Styles/NavBurgerIcon.style';

class NavBurgerIcon extends React.Component {

    render() {
        return (
            <NavBurgerContainer {...this.props}>
                <Stick className={this.props.MenuOpen ? 'open' : ''}/>
            </NavBurgerContainer>
        );
    }
}

export default NavBurgerIcon;